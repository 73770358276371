/* Root Var */
:root {
    --bg: #f5f5f5;
    --text: #000;

    --primary: #0d6974;
    --secondary-red: #28828d;
    --secondary: #bee6ee;
    --secondary-blue: #baecf5;
    --white: #ffffff;
    --black: #000000;
    --gradint-red: var(--primary), var(--secondary-red);
    --gradint-blue: var(--secondary), var(--secondary-blue);
    --gray: #dedede;
    --margin: 10px;
    --padding: 5px;
}

section[data-theme="dark"] {
    --bg: #2d2c2c;
    --text: #fff;
    --primary-red: #28828d;
    --secondary-red: #baecf5;
    --primary-blue: #643cff;
    --secondary-blue: #508cfa;
    --white: #000000;
    --black: #ffffff;
    --gradint-red: var(--primary-red), var(--secondary-red);
    --gradint-blue: var(--primary-blue), var(--secondary-blue);
    --gray: #7a7777;
    --margin: 10px;
    --padding: 5px;

}
.nav-link:focus, .nav-link:hover{
    color: var(--secondary) !important;
}
* {
    transition: all 1s ease-in-out;
    /*transition: background-color 1s ease-in-out;*/
}

.fs-7 {
    font-size: 10px;
}

section[data-theme="dark"] {
    background-color: var(--bg);
    color: var(--text);
}

.bg-bg {
    background-color: var(--bg);
}

.text-text {
    color: var(--text);
}

.background {
    background-color: var(--gray);
    z-index: -1;
}

* {
    font-family: cairo;
    text-decoration: none !important;
}

body {
    background-color: var(--white);
}

.p-5 {
    padding: var(--padding) !important;
}

.m-10 {
    margin: var(--margin);
}

.m-block-10 {
    margin-block: var(--margin);
}

.bg-primary {
    background: var(--primary) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.bg-gray {
    background: var(--gray) !important;
}

.text-gray {
    color: var(--gray) !important;
}

.bg-secondary {
    background: var(--secondary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.bg-gradient-red-h {
    background: linear-gradient(to right, var(--gradint-red));
}

.bg-gradient-blue-h {
    background: linear-gradient(to right, var(--gradint-blue));
}

.bg-gradient-red-v {
    background: linear-gradient(to bottom, var(--gradint-red));
}

.bg-gradient-blue-v {
    background: linear-gradient(to bottom, var(--gradint-blue));
}

.text-bold {
    font-weight: bolder;
}

/* spinner loading */

.loading {
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .1);
    justify-content: center;
    align-items: center;
    position: relative;
}

/* Loading card */
.loading-card {
    aspect-ratio: 1.2;
    width: min(280px, 100%);
    background: var(--gray);
    padding-block: var(--padding);
    padding-inline: var(--padding);
    margin-inline: auto;
    animation: pulse 750ms infinite alternate; /* حركة النبض */
}

.loading-img {
    aspect-ratio: 1/1;
    width: min(270px, 100%);
    background: rgba(213, 213, 213, 1);
    margin-inline: auto;

}

.btn-loading {
    width: min(270px, 100%);
    background: rgba(213, 213, 213, 1);
    height: 60px;
    margin-inline: auto;
    margin-block: var(--margin);

}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* min-nav-bar*/
.main-nav-bar {

    height: 60px !important;

}
.btn-increase{
    background-color: transparent;
    border: 1px solid #FF9900;
    border-radius: 10px;
    padding-inline: 15px;
    padding-block: 5px; display: inline-block;margin-inline: 15px;
    color:  #FF9900;
    font-size: 16px;
    font-weight: bold;
}
.btn-increase:hover{
    background-color: #FF9900;
    color: #FFFFFF;
}

.price {
    top: 0px;
    left: 0px;
    background-color: var(--primary);
    padding-inline: 7px;
    padding-block: 4px;
    border-radius: 3px;
    color: var(--white);
    z-index: 2;
}

/* wrapper */

.wrapper {
    width: min(1320px, 100%);
    min-height: calc(100vh - 57px);
    padding-block: calc(70px + var(--margin));
    margin-inline: auto;

}

/* carousel */
.container-slider {
    background: var(--gray);
    padding: var(--padding);
    border-radius: 8px;
}

.carousel-item {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 7/2;
    position: relative;
    border-radius: 8px;

}

.carousel-item img {
    width: 100%;
    height: 100%;
}

/* Marquee style  */
.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 60px;
    background: var(--gray);
    margin-block: var(--margin);
    border-radius: 8px;

}

.marquee-content {
    display: inline-block;
    animation: marquee-animation 20s linear infinite;
    color: var(--black);
    padding-block: 15px;
    font-size: min(2ch, 16px);

}

/* Card Category */
.card-category {
    aspect-ratio: 1.2;
    width: min(280px, 100%);
    /*background: var(--gray);*/
    padding-block: var(--padding);
    padding-inline: var(--padding);
    margin-inline: auto;
    border-radius: 8px;;

}

.card-category:hover {
    /*background: linear-gradient(to bottom, var(--gradint-blue));*/
    box-shadow: -2px -2px 15px 1px var(--primary);
}

.card-category:hover .loading-img {
    filter: grayscale(80%);
}

.card-category > .loading-img {
    aspect-ratio: 1/1;
    width: min(270px, 100%);

    margin-inline: auto;
    border-radius: 8px;

}

.card-category > .loading-img > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.card-category > .btn-loading {
    width: min(270px, 100%);
    background: linear-gradient(to right, var(--gradint-red));
    height: 60px;
    margin-inline: auto;
    margin-block: var(--margin);
    border-radius: 8px;

}

@keyframes marquee-animation {
    0% {
        transform: translateX(calc(-100vw));
    }
    100% {
        transform: translateX(calc(100vw + 100%));
    }
}

@keyframes category-item-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(20deg);
    }
}

/* Card Product*/
.card {
    width: 100%;
    aspect-ratio: 1/1;
    perspective: 1000px; /* تحديد العمق لتطبيق التحول ثلاثي الأبعاد */
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.card div {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* يحجب الوجوه الخلفية عند الدوران */
    transition: transform 0.8s; /* تطبيق تحول عند التحويل */
}

.group-img {
    width: min(100%, 50px);
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.front {
    background-color: #ccc;

}

.front > .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);

}

.front img {
    width: 100%;
    height: 100%;

}

.back {
    background-color: #333;
    color: #fff;
    transform: rotateY(180deg); /* تحويل الوجه الخلفي بزاوية 180 درجة */
}

.back > .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
}

.back img {
    width: 100%;
    height: 100%;

}

.data-product {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.back .data-product {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.card:hover .front {
    transform: rotateY(-180deg); /* تحويل الوجه الأمامي بزاوية -180 درجة عند تمرير الماوس */
}

.card:hover .back {
    transform: rotateY(0deg); /* تحويل الوجه الخلفي بزاوية 0 درجة عند تمرير الماوس */
}

/*Product Card */
.img-product {
    width: min(100%, 220px);
    aspect-ratio: 1/1;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.img-product > .over-blur {
    position: absolute;
    padding-block: 3px;
    padding-inline: 5px;
    color: var(--primary);
    top: 0;
    left: 0;
    background-color: var(--secondary-blue);

    z-index: 1;
}

.img-product > img {
    width: min(100%, 220px);
    aspect-ratio: 1/1;
    display: block;
    margin: auto;
    z-index: 0;
    position: relative;
}

.toggle-theme {
    display: inline-block;
    width: 50px;
    height: 15px;
    border-radius: 7px;
    border: 1px var(--text) solid;
    background: var(--secondary);
    position: relative;
}

.toggle-theme.active {

    background: var(--gray);

}

.toggle-theme.active:after {
    left: calc(100% - 20px); /* Adjusting for the width of the toggle */
}

.toggle-theme:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    background-color: var(--primary);
    transition: all 700ms ease-in-out;

}

.product-card:hover .title {
    color: var(--primary);
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
}

.title {
    color: var(--secondary-red);
    border: 1px solid var(--primary);
    margin-block: 5px;
    padding-inline: 10px;
    padding-block: 4px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    transition: all 500ms;
}

/*details Product*/
.product-details-img {
    width: min(250px, 50%);
    aspect-ratio: 1/1;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
}

.product-details-img img {
    width: 100%;
    height: 100%;
}

.product-info {
    padding-inline: 5px;
    padding-block: 5px;
    border-radius: 8px;

    margin-block: 5px;
}

.text-black {
    color: var(--black) !important;
}

/*Text Input */
label {
    display: none;
}

input[type='text'].input, input[type='email'].input, input[type='number'].input, input[type='password'].input, textarea.input, .input:focus {
    border: 1px solid var(--primary) !important;
    outline: none !important;
    border-radius: 20px;
    margin-block: 5px;
    background-color: var(--white);
    box-shadow: none;
    color: var(--black);
    text-indent: 5px;
    width: 100%;
    resize: none;
}

.bank-img {
    width: 45px;
    height: 45px;

}

.password-container > i {
    top: 18px;
    left: 10px;
}

.logo-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid var(--secondary);
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-auth {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-position: center;
    object-fit: fill;
    box-shadow: 1px 1px 17px -2px var(--secondary);

}

.card-bill {
    position: relative;
    padding: 20px 10px;
    border-radius: 15px;
    box-shadow: 1px 1px 17px -2px var(--black);
    margin-block: 20px;

    z-index: 0;
}

.shadow {
    box-shadow: -2px 1px 4px 0px var(--text) !important;
}

.animation-bill::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 15px;
    background: linear-gradient(90deg, var(--primary), var(--secondary));
    animation: gradientBorder 3s infinite;
    background-size: 200% 200%;
    z-index: -1;


}

.animation-bill {
    color: var(--white) !important;
    font-size: 13px;
    font-weight: bold;
}

@keyframes gradientBorder {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

@keyframes gradientBorder {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.card-login {
    border: 1px solid var(--gray);
    padding-block: 20px;
    padding-inline: 10px;
    width: min(100%, 450px);
    border-radius: 15px;
    box-shadow: 1px 1px 17px -2px var(--primary);
    margin-block: 45px;
    animation: boxShadowAnimation 3s infinite alternate;
}

.card-profile {
    border: 1px solid var(--gray);
    padding-block: 20px;
    padding-inline: 10px;
    width: min(100%, 550px);
    border-radius: 15px;
    box-shadow: 1px 1px 17px -2px var(--primary);
    margin-block: 45px;
    animation: boxShadowAnimation 3s infinite alternate;
}

.card-profile ~ .input, .card-profile input {
    width: 100%;


}

.card-profile span {
    flex: 0 1 auto;
}

@keyframes boxShadowAnimation {
    0% {
        box-shadow: 1px 1px 25px -2px var(--primary);
    }
    100% {
        box-shadow: 1px 1px 25px -2px var(--secondary);
    }

}

.align-right {
    align-self: flex-start;
}

.check-name {
    cursor: pointer;
}

.check-name.rotate {
    animation: rotate infinite 1s ease-in-out;
}

.response-player {
    font-size: 10px;
    margin-right: 5px;
    background-color: var(--primary);
    color: var(--text);
    padding: 2px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.text-error {
    font-size: 10px;
    font-weight: bold;
    background: linear-gradient(to right, var(--primary), var(--text) 20%, var(--secondary));
    background-size: 200% 100%;
    background-position: 0 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: error 1500ms ease-in-out infinite;
}

.text-error-inverse {
    font-size: 10px;
    font-weight: bold;
    background: linear-gradient(to right, var(--white), var(--secondary) 20%, var(--white));
    background-size: 200% 100%;
    background-position: 0 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: error 1500ms ease-in-out infinite;
}

@keyframes error {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

/*Button Buy*/
.btn-buy {
    outline: 1px var(--primary);
    background: linear-gradient(to right, var(--gradint-red));
    border: none;
    padding-inline: var(--margin);
    padding-block: var(--padding);
    color: var(--white);
    width: min(50%, 150px);
    transition: all 500ms ease-in-out;

}

.btn-buy:hover {
    outline: 1px var(--secondary);
    background: linear-gradient(to right, var(--gradint-blue));
    color: var(--white);
}

/* total_balance */
.total_balance {
    position: fixed;
    top: 180px;
    left: 0px;
    height: 40px;
    padding-inline: 15px;
    padding-block: 7px;
    color: var(--white);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: linear-gradient(to left, var(--gradint-red));
    z-index: 10;

}

/*Footer*/
footer.footer {
    background-color: var(--gray);
}

.facebook-icon {
    color: #508cfa;
}

.twitter-icon {
    color: #7aa4f6;
}

.telegram-icon {
    color: #7aa4f6;
}

.instagram-icon {
    color: #d7693b;
}

label[for='avatar'] {
    display: inline-block;
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.youtube-icon {
    color: #ff0000;
}

.form-control::placeholder {
    color: var(--text) !important;
}

input[type='number'] {
    font-family: 'Arial', sans-serif !important;
}

.whatsapp-icon {
    color: #2cce11;
}

.logo-nav {
    width: 40px;
    aspect-ratio: 1/1;
    display: inline-block;
    margin-inline: 10px;
}

.footer-text {
    color: var(--black);
}

.footer-text:hover {
    color: var(--primary);
}

.card-balance {
    margin-block: 10px;
    margin-inline: 20px;
    box-shadow: 1px 1px 10px var(--primary);
    text-align: center;
    padding-block: 8px;
    cursor: pointer;
    background-color: var(--gray);
}

.card-balance.active {
    background-color: var(--primary);
    color: #fff;
}

.card-coupon {
    background-color: var(--bs-dark);
    color: #fff !important;
    padding: 5px;
}

.card-coupon .title-coupon, .card-coupon .price-coupon {
    font-size: 20px;
    margin-block: 10px;
}

.card-coupon button {
    margin-bottom: 5px;
}